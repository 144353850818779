@font-face {
  font-family: Euclid-Medium;
  src: url("./Font/Euclid_Circular_A_Medium.ttf");
}
@font-face {
  font-family: Euclid-SemiBold;
  src: url("./Font/Euclid_Circular_A_SemiBold.ttf");
}


:root{
  --orange_main:#ec6339;
  --green_main:#39756d;
  --light_orange_main:#f8ece0;
  --light_skyblue:#dcd8e9;
  --blue_main:#23232d;

  --text_white_main:#fff;
  --text_black_main:#000;
  --text_light_orange_main:#d9b778;
  --text_light_blue:#61616b;
  --text_blue:#61616b;
}


.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
  margin: 0;
  font-family: Euclid-SemiBold;
}

p{
  font-family: Euclid-Medium;
}
body {
  overflow-x:hidden;
  overflow-y: auto;
  margin: 0;
  font-family: Euclid-SemiBold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
  color:unset;
  text-decoration: none;
}


/* h1,h2,h3,h4,h5,h6{font-size:${textSize}vw !importante; }
        p{font-size:${textSize - 1.516}vw !importante; } */