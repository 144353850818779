.app__card{
    padding-bottom: 5px;
    background-color: #fff;
    border-radius:10px
}
.card{
    border:unset;
    border-radius:12px;
   
}
.card-footer{
    background-color: unset;
border-top: unset;
}
.app__card__image{
    margin-block: 20px; 
    width:5.7rem;
    height:5.7rem;
    border-radius:10px;
   margin-left:auto;
   margin-right: auto;

}
.app__card__title{
    font-size:0.8rem !important;
    padding-inline: 3px;
    font-family: Euclid-Medium;
    color:#000
}
.app__card__company{
    font-size:0.8rem !important;
    padding-inline: 3px;
    font-family: Euclid-Medium;
    color:#939598;
}
.app__card__icons{
    color:#939598;
}
.app__card__icons__star{
    color:#939598;
    font-size:0.8rem;
}
.app__card__rating{
    font-size:0.8rem !important;
    font-family: Euclid-Medium;
    color:#939598;
}
@media screen and (max-width:596px){
    .card{
        height:270px;
    }
}
@media screen and (min-width:596px){
    .card{
        height:270px;
    }
}
@media screen and (min-width:720px){
    .card{
        height:270px;
    }
}