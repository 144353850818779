
.aboutus__top {
  background-color: #39756d;
  overflow-y: auto;
  overflow-x: hidden;
}
.image_relative{
    position: relative;
}
.aboutus__content {
  color: #fff;
 
}


@media (max-width: 575.98px) {
  .mybutton{
    position: fixed;
    top: 35.9%;
    z-index: 1;
    right: 0;
  
  }
   .aboutus__section2_content__img{
    display: block;
    width: 100%;
    height: 52.5vh;
    object-fit: cover;
    background-size: 'contain';;
}
  .aboutus__section2_content {
    color: white;
    padding-right: 15px;
  }
  .aboutus__section2_content >h5{
    font-size: 1.5rem !important;
  }
  .aboutus__contents>p{
    font-size: 1.1rem;
    font-weight: 100;
    font-family: Euclid-Medium;
  }
  /* .aboutus__content > h4 {
    font-size: 2rem;
  } */
  .aboutus__content {
    padding-block: 10rem;
  }

  .image_container > .circle1 {
    width: 40%;
    position: absolute;
    top: 35px;
    right: -35px;
  
  }
  .image_container > .circle2 {
    width: 32%;
    max-width: 50%;
    position: absolute;
    bottom: 10%;
    right: 6px;
  }

  .image_container > .circle3 {
    width: 27%;
    position: absolute;
    top: 82px;
    left: 14px;

  }
  .image_container > .circle4 {
    width: 22%;
    position: absolute;
    bottom: 4%;
    left: 39px;
  
  }
}

@media (min-width: 575.98px) {
  .aboutus__content > p {
    font-size: 1.2rem;
  }
  .aboutus__section2_content__img{
    display: block;
    width: 100%;
    height: 52.5vh;
    object-fit: cover;
    background-size: 'contain';;
}
    .aboutus__section2_content {
    padding-right: 15px;
  }
  .aboutus__contents>p{
    font-size: 1.1rem;
    font-weight: 100;
    font-family: Euclid-Medium;
  }
  /* .aboutus__content > h4 {
    font-size: 2.5em;
  } */
  .aboutus__content {
    padding-block: 10rem;
  }
  .image_container > .circle1 {
    width: 25%;
position: absolute;
top: 37px;
right: -80px;
  }
  .image_container > .circle2 {
    width: 25%;
    position: absolute;
    bottom: 3%;
    right: 48px;
  }

  .image_container > .circle3 {
    width: 12%;
    position: absolute;
    top: 120px;
    left: 48px;
  }
  .image_container > .circle4 {
    width: 12%;
    position: absolute;
    bottom: 4%;
    left: 39px;
  }
}

/* // `md` se aplica a los dispositivos pequeños (landscape phones, less than 768px) */
@media (min-width: 767.98px) {
  .aboutus__section2_content__img{
    display: block;
    width: 100%;
    /* height: 100vh; */
    object-fit: cover;
  /* background-size : 'contain'; */
  }
  .aboutus__section2_content {
  
  padding-right: 6%;
}
  .aboutus__contents>p{
  font-size: 1.1rem;
  font-weight: 100;
  font-family: Euclid-Medium;
}
  /* .aboutus__content > h4 {
    font-size: 2rem;
  } */
  .aboutus__content {
    padding-block: 8rem;
  }

  .image_container > .circle1 {
    width: 21%;
    position: absolute;
    top: 15px;
    right: -40px;
  
  }
  .image_container > .circle2 {
    width: 16%;
    position: absolute;
    bottom: 30px;
    right: 48px;
  
  }

  .image_container > .circle3 {
    
    width: 9%;
    position: absolute;
    top: 90px;
    left: 19px;
  
  }
  .image_container > .circle4 {
    width: 10%;
    position: absolute;
    bottom: 52px;
    left: 39px;
  }
}

/* // `lg` se aplica a los dispositivos medianos (tablets, less than 992px) */
@media (min-width: 991.98px) {
  .mybutton{
    position: fixed;
    top: 70.6%;
    z-index: 1;
    right: 0;
  
  }
  .custom__padding{
    padding-top: 1rem;
    padding-left: 163px;
    font-weight: 600;
    font-size: 2.2vw !important;
  }
  .aboutus__section2_content__img{
    display: block;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    background-size: 'contain';;
}
    
.aboutus__section2_content {
  padding-block:8%;
  color: white;
  padding-right: 6%;
}
  .aboutus__contents{
    padding-left: 30px;
  }
  .aboutus__contents>p{
    font-size: 1.1rem;
    font-weight: 100;
    font-family: Euclid-Medium;
    padding-right: 50px;
  }
  /* .aboutus__content > h4 {
    font-size: 2.8rem;
  } */
  .image_container > .circle1 {
    width: 17%;
    position: absolute;
    right: -39px;
    top: unset;
    bottom: unset;
    left: unset;
  }
  .image_container > .circle2 {
    width: 13%;
    position: absolute;
    top: 407px;
    right: 247px;
    bottom: unset;
    left: unset;
  }

  .image_container > .circle4 {
    width: 6%;
    position: absolute;
    top: 270px;
    right: 259px;
    bottom: unset;
    left: unset;
  }
  .image_container > .circle3 {
    width: 6%;
    position: absolute;
    top: 19%;
    right: 22rem;
    bottom: unset;
    left: unset;
  }
}

/* // `xl` se aplica a los dispositivos grandes (desktops, less than 1200px) */
@media (min-width: 1199.98px) {
  .aboutus__section2_content__img{
  display: block;
  width: 100%;
  /* height: 100vh; */
  object-fit: cover;
background-size : 'contain';
}
  
.aboutus__section2_content {
  padding-right: 6%;
}
  .aboutus__contents>p{
    font-size: 1.1rem;
    font-weight: 100;
    font-family: Euclid-Medium;
    padding-right: 179px;
  }
  /* .aboutus__content > h4 {
    font-size: 2.8rem;
  } */
  .image_container > .circle1 {
    width: 17%;
    position: absolute;
    right: -39px;
    top: unset;
    bottom: unset;
    left: unset;
  }
  .image_container > .circle2 {
    width: 13%;
    position: absolute;
    top: 407px;
    right: 247px;
    bottom: unset;
    left: unset;
  }

  .image_container > .circle4 {
    width: 6%;
    position: absolute;
    top: 270px;
    right: 259px;
    bottom: unset;
    left: unset;
  }
  .image_container > .circle3 {
    width: 6%;
    position: absolute;
    top: 19%;
    right: 22rem;
    bottom: unset;
    left: unset;
  }
}

/* // `xxl` se aplica a los dispositivos xl (large desktops, less than 1400px) */
@media (min-width: 1399.98px) {
  .aboutus__section2_content__img{
    display: block;
    width: 100%;
    height: 100vh;
    object-fit: cover;
  background-size : 'contain';
  }
  .aboutus__contents>p{
    font-size: 1.1rem;
    font-weight: 100;
    font-family: Euclid-Medium;
    padding-right: 50px;
  }
  /* .aboutus__content > h4 {
    font-size: 2.8rem;
  } */
  .image_container > .circle1 {
    width: 16%;
    position: absolute;
    right: -83px;
  
  }
  .image_container > .circle2 {
    width: 13%;
    position: absolute;
    top: 308px;
    right: 247px;
  }

  .image_container > .circle4 {
    width: 5%;
    position: absolute;
    top: 161px;
    right: 220px;
  }
  .image_container > .circle3 {
    width: 6%;
    position: absolute;
    top: 8%;
    right: 24rem;
  }
}

/* Section 2 */

.aboutus__section2 {
  background-color: #23222C;
}


.aboutus__section2_content >h5{
    font-size: 0.1em;
    word-break: keep-all;
    /* line-height: 2rem; */
    padding-left: 25px;
  }

.quatation{
    font-size:6rem !important;
    line-height: 1;
}
.invert{
  font-size: 6rem;
  line-height: 0;
  padding-bottom: 10px;
  padding-top: 35px;
  transform: scale(-1, -1);
  display: flex;
  margin-right: -19px;

}

/* Section 3 */
.section__3{
  background-position: 20%;
  background-size: cover;
  background-repeat: no-repeat;
}
.section__3__content>h2{

  font-size:2.5rem;
}
.section__3__content>p{
  text-align: left;
  font-size: 1.2rem;

}
/* section 4 */
.section__4{
    background-color:#39756d;
    color:#fff
}

.aboutus__contents>h4{
    font-size: 2.3rem;
}

.aboutus__contents>button{
    background-color:#ec6339;
    border:none;

    padding:10px 15px;
    color:#fff;
    border-radius: 10px;
    font-size: 1.2rem;
}
.aboutus__icon{
  color:#fff;
  font-size: 3.5rem;
  stroke-width: 0.4;
  width: 1.2em !important;
}
/* .aboutus__section2_content__img{
  display: block;
  width: 100%;
  height: 100vh;
  object-fit: cover;
background-size : 'contain';
} */