.contectus__left{
    background-color:#23222C;
    color: #DDD7EB;
}
.contentus__row{  background-color:#23222C;}
.contectus__left>h4{
    
    font-weight: 100;
}
.contectus__right__FFF{
    background-color:#fff;
    border-radius: 10px;
    padding:2rem;
}
.form-control{
    border-radius: 10px;
    border:unset;
    background-color: #F5F5F5;
}
.form-control:focus{
    box-shadow:unset;
    outline: unset;
    background-color: #F5F5F5;
}
.important{
    color:#ec6339;
}
.form-label{
  
    font-family: Euclid-Medium;
}
.order__list{
    padding-left: 5rem;
}
.policy{
    color:#000;
}
.condition{
    color:#A8A9AD;
    font-size: 0.8rem;
}
.submit__button{
    padding:10px 40px;
    color: #fff;
    background-color: #000;
    border: unset;
    border-radius: 10px;
}