.full__list{
  overflow-x: hidden;
}
.full__list__left {
  background-color: #fff;
}
.full__list__left > button {
  padding-block: 6px;
  padding-inline: 10px;
  margin-block: 20px;
  background-color: #ec6339;
  color: #fff;
  border: unset;
  border-radius: 10px;
  z-index: 1;
}
.full__list__family {
  background-color: #c8d8e3;
  padding-inline: 50px;
  padding-top: 50px;
  color: #fff;
}
.full__list__family > h3 {
  padding-bottom: 20px;
  font-size: 1.5rem;
}
.full__list__family > img {
  width: 100%;
}
.full__list__Education {
  background-color: #e4b368;
  color: #fff;
  padding-block: 30px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.full__list__Education > h3 {
  transform: scale(-1, -1);
  writing-mode: tb-rl;
  font-size: 1.2rem;
}
.full__list__Education > img {
  width: 90%;
}
.full__list__col1 {
  background-color: #23222c;
  height: 55%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.full__list__col1 > img {
  position: absolute;
  top: 13px;
  width: 14%;
}
.full__list__col2 {
  background-color: #f2653b;
  color: white;
  display: flex;
  align-items: flex-end;
  padding-inline: 13px;
  padding-bottom: 10px;
  height: 12%;
  font-size: 2rem;
}
.full__list__col2 > h6 {
  font-size: 1.3rem;
}
.full__list__col3 {
  background-color: #39736c;
  height: 12%;
  position: relative;
}
.full__list__col3 > img {
  position: absolute;
  top: 18px;
  right: 0;
  width: 70%;
}
.full__list__col4 {
  background-color: #f8ebde;
  height: 21%;
}
.image__contain__relative {
  position: relative;
}


.full__list__search__content > .link  {
  width: 50%;
}
.full__list__search__content > .link > img {
  width: 10%;
}
.full__list__search__content > h3 {
  padding: 20px;
  font-size: 2rem;
}
.fullList__mid > p {
  font-size: 1.2rem;
  color: #23222c;
}

.input-group-text {
  background-color: #F5F5F5;
  border-radius: 10px;
  border: 1px solid #ffff;
}
.no-border {
  height: 45px;
  border: 1px solid #ffff;
  box-shadow: none; 
}
.no-border:focus {
  box-shadow: none;
  outline: none;
  border: none;
}

.form-select-full {
  background-color: transparent;
  font-weight: 500;
  font-family: Euclid-Medium;
  border: 2px solid darkgray;
  border-radius: 10px;
  max-width:70%;
}
.form-select-full:focus {
  box-shadow: none;
  outline: none;
}
.todos__button > button {
  background-color: rgb(245, 244, 244);
  border: 2px solid lightgray;
  padding: 7px 20px;
  border-radius: 10px;
}
@media(min-width:900px){
  .full__list__search {
    background-color: #c8d8e3;
    padding-block: 150px;
    padding-inline: unset;
    color: #39736c;
  }  
  .form-select-full {
    background-color: transparent;
    font-weight: 500;
    font-family: Euclid-Medium;
    border: 2px solid darkgray;
    border-radius: 10px;
    max-width:100%;
  }
}
@media(min-width:1080px){
  .full__list__search {
    background-color: #c8d8e3;
    padding-block: 150px;
    padding-inline: 80px;
    color: #39736c;
  }  
  .form-select-full {
    background-color: transparent;
    font-weight: 500;
    font-family: Euclid-Medium;
    border: 2px solid darkgray;
    border-radius: 10px;
 
  }
}
@media (max-width: 575.98px) {
  .form-select-full {
  background-color: transparent;
  font-weight: 500;
  font-family: Euclid-Medium;
  border: 2px solid darkgray;
  border-radius: 10px;
  max-width:100%;
}
  .image__contain__relative {
    position: relative;
    padding-bottom: 33px;
  }
  .full__list__left {
    padding-bottom: 35%;
    padding-top: 25%;
  }
  .full__list__col1 {
    background-color: #23222c;
    height: 55%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .full__list__col1 > img {
    position: absolute;
    top: 51%;
    left: -14px;
    width: 44%;
    z-index: 0;
  }
  .full__list__Education > img {
    background-color: transparent;
    position: absolute;
    top: 68%;
    left: 164px;
    width: 149%;
    z-index: 0;
    right: 0;
  }
  .full__list__Education {
    padding-block: unset;
    padding-left: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .full__list__col1,
  .full__list__col2 {
    height: 0;
    background-color: #fff;
  }
  .full__list__search {
    z-index: 1;
  }
  .full__list__col2>img{
    position: absolute;
    top: 29%;
    right: -69px;
    width: 40%;
    z-index: 0;
  }
  .full__list__col{
    z-index: 1;
  }
  .full__list__search {
    background-color: #c8d8e3;
    padding-block: 50px;
    padding-inline: 5px;
    color: #39736c;
  }
  .full__list__search__content > h3 {
    padding: 10px;
    font-size: 2rem;
  }
}

@media (min-width: 575.98px) and (max-width: 850.98px)    {
  .form-select-full {
  background-color: transparent;
  font-weight: 500;
  font-family: Euclid-Medium;
  border: 2px solid darkgray;
  border-radius: 10px;
  max-width:100%;
}
  .image__contain__relative {
    position: relative;
    padding-bottom: 33px;
  }
  .full__list__left {
    padding-bottom: 35%;
    padding-top: 25%;
  }
  .full__list__col1 {
    background-color: #23222c;
    height: 55%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .full__list__col1 > img {
    position: absolute;
top: 51%;
left: -14px;
width: 33%;
z-index: 0;
  }
  .full__list__Education > img {
    background-color: transparent;
    position: absolute;
    top: 65%;
    left: 453px;
    width: 100%;
    z-index: 0;
    right: 0;
  
  
  }
  .full__list__Education {
    padding-block: unset;
    padding-left: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .full__list__col1,
  .full__list__col2 {
    height: 0;
    background-color: #fff;
  }
  .full__list__search {
    z-index: 1;
  }
  .full__list__col2>img{
    position: absolute;
top: 20%;
right: -69px;
width: 30%;
z-index: 0;
  
  }
  .full__list__col{
    z-index: 1;
  }
  .full__list__search {
    background-color: #c8d8e3;
    padding-block: 50px;
    padding-inline: 5px;
    color: #39736c;
  }
  .full__list__search__content > h3 {
    padding: 10px;
    font-size: 2rem;
  }
}
