.heading{
font-size:1.3rem;
font-weight:500;
}
.sub_heading{
    font-size:1.2rem;
    font-weight:500;
    padding-bottom:10px;
}
.content{
    font-size:1.1rem;
    font-weight:500;
    color:rgb(128, 128, 128)
    
}