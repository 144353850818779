.userLogin__left{
    background-color:#F5F5F5;
    color: #22212C;
    padding: 0 10px ;
}
.userLogin__row{  background-color:#23222C;}
.userLogin__left>h4{
    font-weight: 100;
    padding-right: 100px;
    line-height: 1.1;
}
.userLogin__right{
    padding-inline:20%;
}
.userLogin__right__FFF{
    background-color:#fff;
    border-radius: 10px;
    padding:2rem;
}
.form-control{
    border-radius: 10px;
    border:unset;
    background-color: #F5F5F5;
}
.form-control:focus{
    box-shadow:unset;
    outline: unset;
    background-color: #F5F5F5;
}
.important{
    color:#ec6339;
}
.form-label{
  
    font-family: Euclid-Medium;
}
.order__list{
    padding-left: 5rem;
}
.policy{
    color:#000;
}
.condition{
    color:#A8A9AD;
    font-size: 0.8rem;
}
.submit__button{
    padding:10px 40px;
    color: #fff;
    background-color: #000;
    border: unset;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}
.google__color{
    background-color: #E6E7E9;
    padding: 10px 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    width:77%;
    display: flex;
justify-content: center;
}

.google__color>img{
    margin-left: auto;
    margin-right: auto;
}
.userlogin__right__p{
    font-size:1rem;
}
.userLogin__right__heading{
    font-size:2rem;
}
.special{
    text-decoration: underline;
    color: #000;
    font-size: 1rem;
}
@media screen and (max-width:479px) {
    .userLogin__right{
        padding-inline:7%;
    }
}
.family__1{
    height: auto;
}