.user__profile__button{
    background-color: #ec6339;
    color: #fff;
    border:#ec6339;
    padding:6px 15px;
    border-radius: 5px;
    margin-block: 30px;
}
.main{
  position: relative;

  height: 25vh;
  width: 15vw;
  display: flex;
  align-items: center;
   align-content: center;
  justify-content: center;
}

.input-large{
  align-self: flex-end;
  height: 20%;
  width: 100%;
  background-color: rgba(000, 000, 000, 0.5);
  position :absolute;
  bottom: 0;
    display: none;
}
.center{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: rgba(000, 000, 000, 0.5);
    position :absolute;
    top: 50;
    left:50;
    display: flex;
    align-items: center;
     align-content: center;
    justify-content: center;

}
.img-responsive{
    border-radius: 50%;
    width:160px;
    height:160px;
}