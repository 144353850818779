.appinfo {
  background-color: #fff;
}
.appinfo__section1 > img {
  border-radius: 40px;
}
.appinfo__section1
  > .appinfo__section1__scroll
  > .appinfo__section1__images
  > img {
  border-radius: 40px;
}
.appinfo__section1__scroll {
  overflow-x: scroll;
}
.appinfo__grey {
  color: #939598;
}
.icon {
  font-size: 2rem !important;
}
.appinfo__button {
  color: #fff;
  background-color: #f2653b;
  border: unset;
  padding-block: 10px;
  border-radius: 20px;
}
.ShowMore {
  border: unset;
  background-color: transparent;
  color: #f2653b;
}
.appinfo__rating {
  background-color: #ebecec;
  border-radius: 30px;
  padding: 40px 20px;
}
.rating {
  font-size: 6rem !important;
  color: #f2653b;
}
.progress {
  background-color: #939598;
  height: 0.6rem;
}
.progress-bar {
  background-color: #f2653b;
  height: 0.6rem;
  border-radius: 0.45rem;
}
.sm__h6{
  font-size:1.2rem !important;
}
@media (max-width: 575.98px){
  .sm__h6{
    font-size:1.2rem !important;
  }

}
.padding__progress{
  margin-block:9% 4%;
}


.todos{
    color: #f2653b;
}

.gold__rating{
color: #FE8905;
font-size: 1.2rem !important;
}
.app__orange{
  color: #f2653b;
}
.change_size> h5,.change_size> h4,.change_size> h6,.change_size> h1,.h3{
  font-size: 1.4rem !important;
}

.bordr{
  border: 1px solid lightgrey;
}
.clear{
  background-color:unset;
  border:unset;
}
.FavouriteApp{
  color:red;
}