*{
    margin:0;
    padding:0;
}
.header{
    background-color: black;
}
.navbar {
    padding-block:10px ;
}
.nav-link  > h6{
    font-size:1.2rem !important;
}
.nav-link >.button{
    background-color:#8c89c2;
    border: none;
    color: #fff;
    padding: 5px;
    border-radius: 8px;
}
/* .bg-dark {
    background-color:#39756d !important;
} */
.nav-link {
    color: unset;
}
.nav-link:focus, .nav-link:hover {
    color: lightgrey;
  }

 .navbar-toggler-icon {
    /* background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e"); */
    stroke:red !important;
  }
.navbar-toggler{
    border:1px solid darkgray;
}
.navbar-toggler:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }
  .roundImg{
    border-radius: 50%;
  }