.button{
   
    background-color:transparent;
    border:unset;
}
.icons_rating{
    font-size:2rem;
    margin-inline:0.3rem;
}
.on{
    color: #f2653b;
}
.off{
    color:black
}
.tool{
    background-color: #f2653b;
    color:#fff;
    border-radius:20px;
    border:1px solid darkgray;
}
.edit__bor{
    border-radius:20px;
    border:1px solid darkgray;
}