.buttonAss{
    background-color:rgb(50, 50, 50);
    border-radius:10px;
    padding:10px 20px;
    flex: 1;
    width:100%;
    margin-block: 10px;
    color:#fff;
  }
  

.BiReset{
  cursor: pointer;
  color: rgb(143, 143, 143);
}
.BiReset:hover{
  color: rgb(122, 122, 122);
}
.offcanvas-title,.modal-title{
  font-size:1.4rem!important;
}