.home{
    background-color:var(--green_main);
}
.home__orange{
    background-color:var(--orange_main);
}
.home__content {

    color:var(--text_white_main);
}
.home__content>button{
    background-color:var(--orange_main);
    border:none;
    padding:10px 15px;
    color:var(--text_white_main);
    border-radius: 10px;
    font-size: 1.2rem;
    
}
.home__image{
    width:80%;
    height:fit-content;
    
}
/* Inclusión Css */
.home__inclustion{
    background-color: var(--orange_main);
    color: var(--text_white_main)

  ;
}
.home__inclustion__content>.home__inclustion__icon>.bsarrow{
    color:var(--text_white_main);
    /* font-size: 3.5rem; */
    stroke-width: 0.4;
    width: 1.2em !important;
    margin-right: 80px;

}
/* Siguiente sección */
.home__technology{
    background:var(--light_orange_main);
    color: var(--text_light_orange_main);
}

.home__technology__content>.home__technology__icon>.bsarrow{
   
    /* font-size: 3.5rem; */
    stroke-width: 0.4;
    width: 1.2em !important;
    margin-right: 80px;

}

/* Siguiente Sección */

.home__help{
    background:var(--light_skyblue);
    color: var(--text_black_main);
}

.home__help__content>.home__help__icon>.bsarrow{
   
    /* font-size:3.5rem; */
    stroke-width: 0.4;
    width: 1.2em !important;
    margin-right: 80px;

}

@media (max-width: 576px) { 
    .home__content__bgcolor{
        background-color:#23222C;
        color:#fff;
    }
 }
 @media (min-width: 768px) { 
    .home__content__bgcolor{
        background-color:#39756d;
        color:#fff;
    }
 }


 @keyframes blurIn {
    from { 
      filter: blur(30px);
      opacity: 0.7;
    }
    
    to {
      filter: blur(0);
      opacity: 1;
    }
  }
  .blurIn {
    animation-name: blurIn;
  }