.Footer{
    background-color: #23232d;
}
.footer__content>.head{
    color:#61616b;
    margin:0;
}
.footer__content__page>.content1{
    color:#d7d3e2;
}
.footer__content>hr{
    color:#61616b;
   margin: 0;
   height: 2px;
   margin-block: 5px;
}
.footer__icons{
    color:#d7d3e2;
    font-size:3rem;
    margin-inline: 10px;
    border: 2px solid #d7d3e2;
    border-radius: 50%;
    padding: 10px;
}
.footer__content__end>.head{
    color:#61616b;
    margin:0;
    font-size: 1.2rem;
}