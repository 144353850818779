.fam_edu{
  overflow-x: hidden;
}
.fam__edu__content {
  color: white;
  overflow-x: hidden;
}
/* .fam__edu__content > h1 {
  font-size: 2rem;
} */
.fam__edu__content > button {
  background-color: #fff;
  border: unset;
  padding: 10px 20px;
  border-radius: 10px;
}
.fam_edu__section__top {
  background-color: #ec6339;
}
.fam_edu__left__top {
  background-color: #c8d8e3;
  color: #356963;
  height: 50vh;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 3%;
}
.fam_edu__left__top  h4 {
  padding-bottom: 10px;
  font-size: 2.5rem;
  text-align: center;
  transform: scale(-1, -1);
  writing-mode:sideways-lr;
  padding-right: 20px;
}

.fam_edu__left__buttom  h4 {
  padding-bottom: 10px;
  font-size: 2.5rem;
  text-align: center;
}

.fam_edu__right  h4 {
  padding-bottom: 10px;
  font-size: 2.5rem;
  text-align: center;
}
/* Section 2 */

.fam_edu__section__mid {
  background-color: #c8d8e3;
  color: #39736c;
}


/*    sección  */

.fam_edu__section__edu {
  background-color: #f8ebd8;
  color: #e4b863;
}
/* Inclusión */

.fam_edu__section__med {
  background-color: #ddd7eb;
  color: #000;
}
.section__button > button {
  background-color: #ec6339;
  border: none;
  padding: 10px 15px;
  color: #fff;
  border-radius: 10px;
  font-size: 1.2rem;
  margin-block: 48px;
}

/* // pequeños dispositivos (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .fam_edu__section__mid__padding>.offset-lg-1-custom {
    margin-right: unset;
  }
      .fam_edu__right {
        background-color: #23222c;
        color: #ddd7eb;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
      .fam_edu__left__buttom {
        background-color: #e4b368;
        color: #fff; 
        display: flex;
      }
      .section2__content {
        font-family: Euclid-Medium;
        font-weight: 400;
        padding-left:1rem;
        padding-right:2rem;
      }
}

/* // pequeños dispositivos (landscape phones, 576px and up) */
@media (min-width: 576px) {
      .fam_edu__right {
        background-color: #23222c;
        color: #ddd7eb;
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }
      .fam_edu__left__buttom {
        background-color: #e4b368;
        color: #fff; 
      }
      .section2__content {
        font-family: Euclid-Medium;
        font-weight: 400;
        padding-inline:1rem;
      }
}

/* // Dispositivos medianos (tablets, 768px and up) */
@media (min-width: 768px) {
  .section2__content {
  font-family: Euclid-Medium;
  font-weight: 400;
   padding-inline:1rem;
}
      .fam_edu__right {
        background-color: #23222c;
        color: #ddd7eb;
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }
      .fam_edu__left__buttom {
        background-color: #e4b368;
        color: #fff; 
      }
}

/* // dispositivos grandes (desktops, 992px and up) */
@media (min-width: 992px) {
  .col-lg-custom-size-div2 {
    width: 1911px;
  margin-left: 50px;
  }
  .col-lg-Custom-Size{
    width: 36%;
  }
  .offset-send-right{
    padding-left: 13.333%;
    padding-right: 1.9%;
  }
  .fam_edu__section__mid__padding>.offset-lg-1-custom {
    margin-right: 8.33333333%;

  }
  .section2__content {
    padding-left: 5rem;
    padding-right: 4rem;
    font-family: Euclid-Medium;
    font-weight: 400;
  }
    .fam_edu__right  h4 {
        padding-bottom: 10px;
        font-size: 2.5rem;
        text-align: center;
      }
      .fam_edu__left__buttom  h4 {
        padding-bottom: 10px;
        font-size: 2.5rem;
        text-align: center;
      }
      .fam_edu__left__top > h4 {
        padding-bottom: 10px;
        font-size: 2.5rem;
        text-align: center;
      }
      .fam_edu__right {
        background-color: #23222c;
        color: #ddd7eb;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    
      }
  
      .fam_edu__left__buttom {
        background-color: #e4b368;
        color: #fff; 
      }
}

/* // dispositivos XL (large desktops, 1200px and up) */
@media (min-width: 1200px) {

  .col-lg-Custom-Size{
    width:31%;
    max-width: 33%;
  }
  .offset-send-right{
    padding-left: 17.333%;
    padding-right: 7.9%;
  }
  .fam_edu__left__buttom {
    background-color: #e4b368;
    color: #fff;
    height: 40vh;
  }
  .fam_edu__right {
    background-color: #23222c;
    color: #ddd7eb;
    height: 80vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .fam_edu__left__buttom {
    background-color: #e4b368;
    color: #fff;
    height: 30vh;
  }
}
.fam_edu__section__mid__padding{
  padding-top:10%;
}
.d-contents{
  display: contents;
}